/* =========== Google Fonts ============ */
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap");

/* =============== Globals ============== */
* {
  font-family: "Ubuntu", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  vertical-align:'baseline';
}

:root {
  /*--blue: #2a2185;*/
  --blue: #a9000c;
  --white: #fff;
  --gray: #f5f5f5;
  --gray2: #515151;
  --gray3: #797979;
  --gray4: #313131;
  --black1: #222;
  --black2: #999;
}

body {
  min-height: 100vh;
  overflow-x: hidden;
}

.container {
  position: static;
  width: 100%;
}

/* =============== Navigation ================ */
.navigation {
  position: fixed;
  width: 210px;
  height: 100%;
  background: var(--blue);
  border-left: 10px solid var(--blue);
  transition: 0.5s;
  overflow: hidden;
}

.navigation.active {
  width: 80px;
}

.navigation ul {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.navigation ul li {
  position: relative;
  width: 100%;
  list-style: none;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

.navigation ul li:hover {
  font-size: larger;
}

.navigation ul li.hovered {
  font-size: larger;
  background-color: var(--white);
}

.navigation ul li:nth-child(1) {
  font-size: x-large;
  margin-bottom: 20px;
  pointer-events: none;
}

.navigation ul li a {
  position: relative;
  display: block;
  width: 100%;
  display: flex;
  text-decoration: none;
  color: var(--white);
}

.navigation ul li:hover a {
  color: var(--white)
}

.navigation ul li.hovered a {
  color: var(--blue);
}

.navigation ul li a .icon {
  position: relative;
  display: block;
  min-width: 60px;
  height: 60px;
  line-height: 75px;
  text-align: center;
}

.navigation ul li a .icon ion-icon {
  font-size: 1.75rem;
}

.navigation ul li a .title {
  position: relative;
  display: block;
  padding: 0 10px;
  font-size: large;
  line-height: 70px;
  text-align: start;
  white-space: nowrap;
}

/* --------- curve outside ---------- */

.navigation ul li.hovered a::before {
  content: "";
  position: absolute;
  right: 0;
  top: -50px;
  width: 50px;
  height: 50px;
  background-color: transparent;
  border-radius: 50%;
  box-shadow: 35px 35px 0 10px var(--white);
  pointer-events: none;
}

.navigation ul li.hovered a::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: -50px;
  width: 50px;
  height: 50px;
  background-color: transparent;
  border-radius: 50%;
  box-shadow: 35px -35px 0 10px var(--white);
  pointer-events: none;
}

/* ===================== Main ===================== */
.main {
  position: absolute;
  width: calc(100% - 220px);
  left: 210px;
  overflow-x: auto;
  min-height: 100vh;
  background: var(--white);
  transition: 0.5s;
}

.main.active {
  width: calc(100% - 80px);
  left: 80px;
}

.topbar {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  /*align-items: center;*/
  padding: 5px 5px;
}

.toggle {
  position: relative;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5rem;
  cursor: pointer;
}

.search {
  position: relative;
  width: 400px;
  margin: 0 10px;
}

.search label {
  position: relative;
  width: 100%;
}

.search label input {
  width: 100%;
  height: 40px;
  border-radius: 40px;
  padding: 5px 20px;
  padding-left: 35px;
  font-size: 18px;
  outline: none;
  border: 1px solid var(--black2);
}

.search label ion-icon {
  position: absolute;
  top: 0;
  left: 10px;
  font-size: 1.2rem;
}

.user {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
}

.user img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* =============== Machine Lists ================ */
.machine-container {
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: space-evenly;
  background-color: rgb(255, 255, 255);
}

.machine-container>div {
  margin: 10px;
  background-color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  color: black;
  border-color: grey;
  border-style: solid;
  border-radius: 15px;
  text-align: center;
  box-shadow: 5px 10px 8px #888888;
}

.machine-container>div>img {
  width: 350px;
  height: 200px;
  padding: 5px;
  border-radius: 20px;
}


/* ======================= Cards ====================== */

.cardBox {
  position: relative;
  width: 100%;
  padding: 5px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
}

.cardBox,
.card {
  position: relative;
  background: var(--white);
  padding: 5px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  box-shadow: 0 7px 25px rgba(0, 0, 0, 0.2);
}

.cardBox .card .numbers {
  position: relative;
  font-weight: 500;
  font-size: 1.5rem;
  padding-top: 5px;
  color: var(--blue);
}

.cardBox .card .cardName {
  color: var(--blue);
  font-size: 1rem;
  font-weight: 500;
  margin-top: 0px;
}

.cardBox .card .iconBx {
  font-size: 1rem;
  color: var(--black2);
}

.cardBox .card #sensor {
  padding-top: 5px;
  font-size: 2rem;
  color: var(--blue)
}

.cardBox .card:hover #sensor {
  padding-top: 5px;
  font-size: 2rem;
  color: var(--white)
}

/*card box in dashboard */

.cardBox .card #normal {
  padding-top: 5px;
  font-size: 2rem;
  color: #8de02c;
}

.cardBox .card #warning {
  padding-top: 5px;
  font-size: 2rem;
  color: #e9b10a;
}

.cardBox .card #critical {
  padding-top: 5px;
  font-size: 2rem;
  color: #f00;
}

.cardBox .card:hover {
  background: var(--blue);
}

.cardBox .card:hover .numbers,
.cardBox .card:hover .cardName,
.cardBox .card:hover .iconBx {
  color: var(--white);
}

/*cardbox1*/

.cardBox1 {
  position: relative;
  width: 100%;

  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 1fr auto;
  grid-gap: 5px;
}

.cardBox1 .card {
  position: relative;
  background: var(--gray2);
  padding: 5px;
  border-radius: 20px;
  display: flex;
  justify-content: space-evenly;
  cursor: pointer;
  align-items: center;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.cardBox1 .card .numbers {
  position: relative;
  font-weight: 500;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  color: var(--white);
}

.cardBox1 .card .cardName {
  color: var(--white);
  font-size: 0.8rem;
  font-weight: 500;
  margin-top: 0px;
}

.cardBox1 .card .iconBx {
  font-size: 1rem;
  color: var(--black2);
}

.cardBox1 .card #sensor {
  padding-top: 5px;
  font-size: 2rem;
  color: var(--white)
}

.cardBox1 .card:hover #sensor {
  padding-top: 5px;
  font-size: 2rem;
  color: var(--white)
}

/*card box in dashboard */
.cardBox1 .card #normal {
  padding-top: 5px;
  font-size: 2rem;
  color: #8de02c;
}

.cardBox1 .card #warning {
  padding-top: 5px;
  font-size: 2rem;
  color: #e9b10a;
}

.cardBox1 .card #critical {
  padding-top: 5px;
  font-size: 2rem;
  color: #f00;
}

.cardBox1 .card:hover {
  background: var(--blue);
}

.cardBox1 .card:hover .numbers,
.cardBox1 .card:hover .cardName,
.cardBox1 .card:hover .iconBx {
  color: var(--white);
}

/*cardbox2*/
.cardBox2 {
  position: relative;
  width: 100%;
  padding: 5px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 5px;
}

.cardBox2 .card {
  position: relative;
  background: var(--gray2);
  padding: 5px;
  border-radius: 20px;
  display: flex;
  justify-content: space-evenly;
  cursor: pointer;
  align-items: center;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.cardBox2 .card .numbers {
  position: relative;
  font-weight: 500;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  color: var(--white);
}

.cardBox2 .card .cardName {
  color: var(--white);
  font-size: 1rem;
  font-weight: 500;
  margin-top: 0px;
}

.cardBox2 .card .iconBx {
  font-size: 1rem;
  color: var(--black2);
}

.cardBox2 .card #sensor {
  padding-top: 5px;
  font-size: 2rem;
  color: var(--white)
}

.cardBox2 .card:hover #sensor {
  padding-top: 5px;
  font-size: 2rem;
  color: var(--white)
}

/*card box in dashboard */
.cardBox2 .card #normal {
  padding-top: 5px;
  font-size: 2rem;
  color: #8de02c;
}

.cardBox2 .card #warning {
  padding-top: 5px;
  font-size: 2rem;
  color: #e9b10a;
}

.cardBox2 .card #critical {
  padding-top: 5px;
  font-size: 2rem;
  color: #f00;
}

.cardBox2 .card:hover {
  background: var(--blue);
}

.cardBox2 .card:hover .numbers,
.cardBox2 .card:hover .cardName,
.cardBox2 .card:hover .iconBx {
  color: var(--white);
}

/*cardbox3*/
.cardBox3 {
  position: relative;
  width: 100%;
  padding: 5px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 1fr;
  grid-gap: 10px;
}

.cardBox3 .card {
  position: relative;
  background: var(--white);
  padding: 5px;
  border-radius: 20px;
  display: flex;
  justify-content: space-evenly;
  cursor: pointer;
  align-items: center;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.cardBox3 .card .numbers {
  position: relative;
  font-weight: 500;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  color: var(--blue);
}

.cardBox3 .card .cardName {
  color: var(--blue);
  font-size: 1rem;
  font-weight: 500;
  margin-top: 0px;
}

.cardBox3 .card .iconBx {
  font-size: 1rem;
  color: var(--black2);
}

.cardBox3 .card #sensor {
  padding-top: 5px;
  font-size: 2rem;
  color: var(--blue)
}

.cardBox3 .card:hover #sensor {
  padding-top: 5px;
  font-size: 2rem;
  color: var(--white)
}

/*card box in dashboard */
.cardBox3 .card #normal {
  padding-top: 5px;
  font-size: 2rem;
  color: #8de02c;
}

.cardBox3 .card #warning {
  padding-top: 5px;
  font-size: 2rem;
  color: #e9b10a;
}

.cardBox3 .card #critical {
  padding-top: 5px;
  font-size: 2rem;
  color: #f00;
}

.cardBox3 .card:hover {
  background: var(--blue);
}

.cardBox3 .card:hover .numbers,
.cardBox3 .card:hover .cardName,
.cardBox3 .card:hover .iconBx {
  color: var(--white);
}


/* ================== Attach Sensor Elements ============== */
#attachsensor_element {
  border: solid rgb(0, 107, 164) 2px;
  background-color: rgb(207, 233, 247);
  border-radius: 20px;
  padding: 7px;
  margin: 5px;
}

/*==================== Dropzone ===========================*/
#Dropzone{
  margin: 5px;
  padding: 5px;
  border: dashed  black 2px ;
  background-color: rgb(223, 223, 223);
  border-radius: 10px;
}

/* ================== Tooltip ========================= */
.tooltip {
  position: relative;
  display: inline-block;
  /*border-bottom: 1px dotted black;*/
}


.tooltip .tooltiptext {
  visibility: hidden;
  width: 220px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

/* ================== Login Form ======================= */
/* Login container styles */
.login-container {
  background-color: #a9000c;
  color:white;
  padding: 20px;
  margin:20px;
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  width: 500px;
  text-align: center;
}
.login-container>h1 {
  font-size:20px;
  padding:10px;
  font-style:bold;
}
/* Form styles */
.login-container>form {
  display: flex;
  flex-direction: column;
}

.login-container>form>input {
  color:black;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #080808;
  border-radius: 4px;
  font-size: 14px;
}

.login-container>form>button {
  background-color: #007bff;
  color: #ffffff;
  border: none;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.login-container>form>button:hover {
  background-color: #0056b3;
}
/* ================== User Management ================= */
#users{
  border-radius: 20px;
  background-color: var(--gray2);
  box-shadow: 0 7px 25px rgba(0, 0, 0, 0.3);
  width : 200px;
  height: 100px;
  align-items: center;
  padding: 10px;
  margin: 10px;
  color:rgb(255, 255, 255);
  font-style:bold;
  text-align: center;
  font-size:larger;
}
/* ================== Order Details List ============== */
.details {
  position: relative;
  width: 100%;
  padding: 5px;
  display: grid;
  grid-template-columns: 6fr 1fr;
  grid-gap: 5px;
}

.details .map {
  position: relative;
  display: grid;
  height: 70vh;
  width: 100%;
  background: var(--gray2);
  padding: 20px;
  box-shadow: 0 7px 25px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
}

.details .recentOrders {
  position: relative;
  display: grid;
  min-height: 500px;
  background: var(--white);
  padding: 20px;
  box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
}

.details .cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.cardHeader h2 {
  font-weight: 600;
  color: var(--white);
}

.cardHeader h3 {
  font-weight: 400;
  color: var(--black2);
}

.cardHeader h3:hover {
  font-weight: 600;
  color: var(--white);
}

.cardHeader .btn {
  position: relative;
  padding: 5px 10px;
  background: var(--blue);
  text-decoration: none;
  color: var(--white);
  border-radius: 6px;
}

.details table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.details table thead td {
  font-weight: 600;
}

.details .recentOrders table tr {
  color: var(--black1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.details .recentOrders table tr:last-child {
  border-bottom: none;
}

.details .recentOrders table tbody tr:hover {
  background: var(--blue);
  color: var(--white);
}

.details .recentOrders table tr td {
  padding: 10px;
}

.details .recentOrders table tr td:last-child {
  text-align: end;
}

.details .recentOrders table tr td:nth-child(2) {
  text-align: end;
}

.details .recentOrders table tr td:nth-child(3) {
  text-align: center;
}

.status.delivered {
  padding: 2px 4px;
  background: #8de02c;
  color: var(--white);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}

.status.pending {
  padding: 2px 4px;
  background: #e9b10a;
  color: var(--white);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}

.status.return {
  padding: 2px 4px;
  background: #f00;
  color: var(--white);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}

.status.inProgress {
  padding: 2px 4px;
  background: #1795ce;
  color: var(--white);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}

.recentCustomers {
  position: relative;
  display: grid;
  min-height: 500px;
  padding: 20px;
  background: var(--white);
  box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
}

.recentCustomers .imgBx {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  overflow: hidden;
}

.recentCustomers .imgBx img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.recentCustomers table tr td {
  padding: 12px 10px;
}

.recentCustomers table tr td h4 {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2rem;
}

.recentCustomers table tr td h4 span {
  font-size: 14px;
  color: var(--black2);
}

.recentCustomers table tr:hover {
  background: var(--blue);
  color: var(--white);
}

.recentCustomers table tr:hover td h4 span {
  color: var(--white);
}

/* ====================== Alarm List ================================= */

.alarmLists {
  position: relative;
  width: 300px;
  max-height: 70vh;
  overflow-y: auto;
  overflow-x: none;
  padding: 20px;
  background: var(--gray2);
  box-shadow: 0 7px 25px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
}

.alarmLists .cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: rgb(255, 255, 255) solid 1px;
  padding-bottom: 10px;
}

.alarmLists .imgBx {
  position: relative;
  width: 40px;
  height: 30px;
  border-radius: 50px;
  overflow: hidden;
}

.alarmLists .imgBx img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.alarmLists table tr {
  background-color: var(--gray4);
  border-bottom: rgb(255, 255, 255) solid 1px;
  border-top: rgb(255, 255, 255) solid 1px;
}

.alarmLists table tr td {
  height: 10px;
  margin: 0px;
  padding: 5px 5px;

}

.alarmLists table tr td h4 {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2rem;
  color: var(--white);
}

.alarmLists table tr td h4 span {
  font-size: 12px;
  font-weight: 500;
  color: var(--white);
}

.alarmLists table tr td h5 span {
  font-size: 10px;
  font-weight: 500;
  color: var(--white);
}

.alarmLists table tr:hover {
  background: var(--gray3);
  color: var(--white);
}

.alarmLists table tr:hover td h4 span {
  color: var(--white);
}

/* ====================== Responsive Design ========================== */

@media (max-width: 991px) {
  .navigation {
    left: -300px;
  }

  .navigation.active {
    width: 300px;
    left: 0;
  }

  .main {
    width: 100%;
    left: 0;
  }

  .main.active {
    left: 300px;
  }

  .cardBox {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .details {
    grid-template-columns: 1fr;
  }

  .recentOrders {
    overflow-x: auto;
  }

  .status.inProgress {
    white-space: nowrap;
  }
}

@media (max-width: 480px) {
  .cardBox {
    grid-template-columns: repeat(1, 1fr);
  }

  .cardHeader h2 {
    font-size: 20px;
  }

  .user {
    min-width: 40px;
  }

  .navigation {
    width: 100%;
    left: -100%;
    z-index: 1000;
  }

  .navigation.active {
    width: 100%;
    left: 0;
  }

  .toggle {
    z-index: 10001;
  }

  .main.active .toggle {
    color: #fff;
    position: fixed;
    right: 0;
    left: initial;
  }
}

/* gridjs */
.gridjs-tr-class:hover {
  background: var(--blue);
  color: var(--white);
}